/* https://docs.amplify.aws/ui/customization/theming/q/framework/react */
:root {
    --amplify-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    --amplify-primary-color: #68b386;
    --amplify-primary-tint: #439463;
    --amplify-primary-shade: #89C9A3;
}

amplify-authenticator, .amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: calc(100vh - 64px);
    overflow: hidden;
    background-color: #ebebeb;
    background-image: url("/static/themes/onepirate/productCurvyLines.png");
    background-position: center;
}